import React from 'react'
import styled from 'styled-components'
import HeaderLink from './link'
import { Container, Row, Grid, OnlyPC } from '../responsive/'

export default props => <OnlyPC>
        <HeaderPC {...props}>
          <Container max='true'>
            <Row>
              <Grid pc="2.7">
                <HeaderLink to="/">DrupalCampDEN Japan Vol.1</HeaderLink>
              </Grid>
              <Grid pcOffset="0.5" pc="1">
                <HeaderLink to="/" active={props.active === 'home'}>Home</HeaderLink>
              </Grid>
              <Grid pc="1.5">
                <HeaderLink to="/about" active={props.active === 'about'}>About DEN</HeaderLink>
              </Grid>
              <Grid pc="1.2">
                <HeaderLink to="/sessions" active={props.active === 'sessions'}>セッション</HeaderLink>
              </Grid>
              <Grid pc="1">
                <HeaderLink to="/venue" active={props.active === 'venue'}>開催場所</HeaderLink>
              </Grid>
              <Grid pc="1.7">
                <HeaderLink to="/volunteer" active={props.active === 'volunteer'}>ボランティア募集</HeaderLink>
              </Grid>
              <Grid pc="2.3">
                <HeaderLink to="/diversity-inclusion" active={props.active === 'diversity-inclusion'}>多様性とインクルージョン</HeaderLink>
              </Grid>
            </Row>
          </Container>
        </HeaderPC>
      </OnlyPC>

const HeaderPC = styled.div`
  height: 3.5em;
  width: 100%;
  z-index: 999;
`
