import React from 'react'
import styled from 'styled-components'

export default props => <EntryOuter><Entry href='https://drupal-meetup-den.connpass.com/event/100818'>
参加申し込みはこちら
</Entry></EntryOuter>

const EntryOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Entry = styled.a`
  padding: 1em 2em;
  text-decoration: none;
  color: #0098cb;
  border: solid 2px #0098cb;
  border-radius: 3px;
  transition: .4s;
  &:hover {
    background: #0098cb;
    color: white;
   }
`
