import React from 'react'
import styled from 'styled-components'
import FooterLink from './link'
import { Container, Row, Grid, Inner} from '../responsive/'

export default props => <Footer>
  <Container>
    <Links>
    <Row>
      <Grid pcOffset='1' pc='1' sp='6'>
        <FooterLink to='/'>HOME</FooterLink>
      </Grid>
      <Grid pc='1.5' sp='6'>
        <FooterLink to='/about'>About DEN</FooterLink>
      </Grid>
      <Grid pc='1.2' sp='6'>
        <FooterLink to='/sessions'>セッション</FooterLink>
      </Grid>
      <Grid pc='1.2' sp='6'>
        <FooterLink to='/venue'>開催場所</FooterLink>
      </Grid>
      <Grid pc='2' sp='6'>
        <FooterLink to='/volunteer'>ボランティア募集</FooterLink>
      </Grid>
      <Grid pc='2.5' sp='6'>
        <FooterLink to='/diversity-inclusion'>多様性とインクルージョン</FooterLink>
      </Grid>
    </Row>
    </Links>
    <Copy>
      <Inner center size='0.8em'>
        <p><Drupal href='https://www.drupal.org/' target='_blank' rel="noopener noreferrer">Drupal </Drupal>
        is a registered trademark of Dries Buytaert.</p>
        Copyrights ©DEN. All rights reserved.
      </Inner>
    </Copy>
  </Container>
</Footer>

const Links = styled.div`
  padding-top:2em;
  padding-bottom:2em;
`

const Drupal = styled.a`
  color: white;
  text-decoration: none;
`

const Footer = styled.div`
  margin-top:3em;
  background:#1f7ea7;
`

const Copy = styled.div`
  color: #FFF;
  padding-bottom:2em;
`
