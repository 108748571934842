import React from 'react'
import {Inner} from '../components/responsive/'
import Entry from '../components/entry'

export default props => <div>
  <Inner
    color='#0098cb'
    size='1.5em'
    marginTop='2em'
    bold
    center
  >
  Drupal Camp DEN Japan Vol.1<br/>
  2018年11月17日(土)
  </Inner>
  <Inner
    color='#0098cb'
    size='1.2em'
    marginTop='0.5em'
    paddingBottom='2em'
    bold
    center
  >
  東京都大田区南蒲田1-20-20 大田区産業プラザPio 2F biz BEACH CoWorking Pio：A会議室 B会議室
  </Inner>
  <Entry/>
</div>
