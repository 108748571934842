import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Grid} from '../components/responsive/'
import H1 from '../components/h1'
import Header from '../components/header/header'
import ContainerFooter from '../components/containerFooter'
import Footer from '../components/footer/footer'
import Wrap from '../components/wrap'
import Metadata from '../components/metadata'
import Session from '../components/sessionPlan'
import SessionTop from '../components/sessionTop'
import './index.css'

export default ({ data }) => (
      <div>
      <Metadata title='セッション'/>
      <Wrap>
      <Header active='sessions'/>
      <Container backgroundColor='#FFF' paddingTop='1em' marginTop='4.5em'>
      <H1 fluid={data.session.childImageSharp.fluid} info='アメリカ DrupalCONの様子'>
      セッション
      </H1>
      <SessionTop title='Keynote speech' place='2F biz BEACH CoWorking'/>
      <Session session={{
        field_session_start : '10:10',
        field_session_end : '11:00',
        field_session_name : 'The future of experiences and Drupal',
        field_session_user : 'Acquia OCTO Director Preston So',
        field_session_movie_url : 'https://www.youtube.com/watch?v=6YQt_C4gCN0',
        field_session_presentation_url : 'https://den-japan.org/sites/default/files/den2018presentation/The%20future%20of%20experiences%20and%20Drupal%20%28DEN%20Camp%20Tokyo%202018%20keynote%29.pdf'
      }}/>
      <Row>
        <Grid pc='6' sp='6'>
          <SessionTop title='Track A' place='1F A会議室'/>
        </Grid>
        <Grid pc='6' sp='6'>
          <SessionTop title='Track B' place='1F B会議室'/>
        </Grid>
      </Row>
      <Row>
      {data.camp2018.rest.sessions.map((session, key) => {
        return<Grid key={key} pc={session.field_session_track==='24' ? '12' : '6'}
          sp={session.field_session_track==='24' ? '12' : '6'}>
            <Session session={session}/>
          </Grid>
      })}
      </Row>
      <ContainerFooter/>
      </Container>
      <Footer/>
      </Wrap>
      </div>
    )

export const query = graphql`
  query {
    session:file(relativePath: { eq: "session.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    camp2018{
      rest{
        sessions {
          field_session_user
          field_session_name
          field_session_end
          field_session_start
          field_session_track
          field_session_movie_url
          field_session_presentation_url
        }
      }
    }
  }
`
