import React from 'react'
import Helmet from 'react-helmet'

export default props => <Helmet
  htmlAttributes={{ lang : 'ja' }}
  title={(props.title ? props.title + ' | ' : '') + 'Drupal Camp DEN Japan 2018'}
  meta={[
    { name: 'google-site-verification', content: 'H5p26Tfyi7RLtaRwmXUd-yAxAHlZgtKBzdJnkgWiZUU' },
    { name: 'description', content: '2018年11月17日、東京 羽田でDrupal Camp DENを開催。' },
    { name: 'viewport',
      content:'width=device-width, initial-scale=1,maximum-scale=1,minimum-scale=1, user-scalable=no',
    },
    { httpEquiv: 'content-language', content: 'ja' },
    { property: 'og:title', content: 'Drupal Camp DEN Japan 2018' },
    { property: 'og:type', content: 'website' },
    { property: 'og:description', content: '2018年11月17日、東京 羽田でDrupal Camp DENを開催。' },
    { property: 'og:url', content: 'http://drupal-camp2018.den-japan.org' },
    { property: 'og:site_name', content: 'Drupal Camp DEN Japan 2018' },
    { property: 'og:image', content: 'https://den-japan.org/sites/default/files/styles/social_xx_large/public/2018-08/20180802.png' },
  ]}
  link={[
      { rel: 'shortcut icon', type: 'image/png', href: 'https://den-japan.org/sites/default/files/den_logo_fav1.png' }
  ]}
/>
