import React from 'react'
import styled from 'styled-components'
import Image from './image'
import {Row, Grid, Inner} from './responsive/'
import {OnlyPC,OnlySP} from '../components/responsive/'

export default props => <H1>
  {!props.fluid &&
    <div>
    <OnlyPC>
      <Inner
        size='3em'
        as='h1'
        color='#0098cb'
        marginTop='50px'
        lineHeight='1.5em'
        center>
        {props.children}
      </Inner>
    </OnlyPC>
    <OnlySP>
      <Inner
        size='2.5em'
        as='h1'
        color='#0098cb'
        marginTop='50px'
        lineHeight='1.5em'
        center>
        {props.children}
      </Inner>
    </OnlySP>
    </div>
  }
  {props.fluid &&
    <div>
    <OnlyPC>
    <Row style={{height:'250px'}}>
      <Grid pc='5'>
        <Image {...props}/>
        <Inner as='p' color='#7f7f7f' center>{props.info}
        </Inner>
      </Grid>
      <Grid pc={props.long ? '6' : '4'} pcOffset={props.long ? '1' : '2.5'}>
        <Inner
          size='3em'
          as='h1'
          color='#0098cb'
          marginTop='100px'>
          {props.children}
        </Inner>
      </Grid>
    </Row>
    </OnlyPC>
    <OnlySP>
    <Inner
      size='2.5em'
      as='h1'
      color='#0098cb'
      center>
      {props.children}
    </Inner>
    <Image {...props}/>
    </OnlySP>
    </div>}
  </H1>

const H1 = styled.div`
`
