import React from 'react'
import {Inner} from '../components/responsive/'
import size from './responsive/size'
import styled from 'styled-components'

export default props => <Inner marginLeft='0.2em' marginRight='0.2em' marginTop='1em' padding='1em' border borderColor='#DDD'
backgroundColor={'#FBFBFB'}>
  {props.session.field_session_track!=='24' &&
  <Inner pcSize='1.5em' spSize='0.8em' color='#7f7f7f' bold>
    {props.session.field_session_start} - {props.session.field_session_end}
  </Inner>}
  {props.session.field_session_track!=='24' &&
  <SessionName>
  <Inner pcSize='1.1em' spSize='0.8em' bold marginBottom='1em' marginTop='1em'>
    {props.session.field_session_name}
  </Inner>
  </SessionName>}
  {props.session.field_session_track==='24' &&
  <Inner pcSize='1.1em' spSize='0.8em' bold marginBottom='1em' marginTop='1em'>
    {props.session.field_session_name}
  </Inner>}
  {props.session.field_session_track!=='24' &&
  <UserName>
  <Inner pcSize='1em' spSize='0.6em' color='#7f7f7f'>
    {props.session.field_session_user}
  </Inner>
  </UserName>}
  {props.session.field_session_track!=='24' &&
  <After>
  <Inner pcSize='1em' spSize='0.6em' color='#7f7f7f' marginTop='1.5em'>
    {props.session.field_session_presentation_url &&
      <Item href={props.session.field_session_presentation_url} target='_blank' rel="noopener noreferrer">セッション資料</Item>
    }
    {props.session.field_session_movie_url &&
      <Item href={props.session.field_session_movie_url} target='_blank' rel="noopener noreferrer">Youtube</Item>
    }
  </Inner>
  </After>}
</Inner>

const SessionName = styled.div`
  height:4em;
  @media only screen and (max-width: ${size.sp}px) {
    height:6em;
  }
`

const UserName = styled.div`
  height:1em;
  @media only screen and (max-width: ${size.sp}px) {
    height:1.5em;
  }
`

const After = styled.div`
  height:1em;
  font-size:0.8em;
  @media only screen and (max-width: ${size.sp}px) {
    height:1.5em;
  }
`

const Item = styled.a`
  margin-right:1em;
`
