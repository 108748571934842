import React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'
import { Inner } from '../responsive/'

export default props => <div>
  {props.active && <Inner
    center
    margin="0.5em"
    marginTop="1em"
    borderBottom
    bold
    borderColor="#0098cb"><HeaderLinkActive to={props.to}
    color={props.color}>
    {props.children}
  </HeaderLinkActive></Inner>}
  {!props.active && <Inner
    center
    margin="0.5em"
    marginTop="1em"
    color={props.color}><HeaderLink to={props.to}
    color={props.color}>
    {props.children}
  </HeaderLink></Inner>}
</div>

const HeaderLink = styled(Link)`
  ${props => props.color ? 'color: ' + props.color : 'color: #333'};
  text-decoration: none;
  font-size:0.8em;
  @media only screen and (max-width: 1000px) {
    font-size:0.8em;
  }
`

const HeaderLinkActive = styled(Link)`
  color: #0098cb;
  text-decoration: none;
  font-size:0.8em;
  @media only screen and (max-width: 1000px) {
    font-size:0.5em;
  }
  @media only screen and (max-width: 768px) {
    font-size:0.8em;
  }
`
