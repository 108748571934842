import React from 'react'
import styled from 'styled-components'
import HeaderPC from './headerPC'
import HeaderSP from './headerSP'

export default props => {
  return (
    <Header {...props}>
      <HeaderPC {...props} active={props.active} />
      <HeaderSP {...props}/>
    </Header>
  )
}

const Header = styled.div`
`
