import React from 'react'
import {Inner} from '../components/responsive/'

export default props =><Inner
    pcSize='2em'
    spSize='1.3em'
    as='h2'
    color='#0098cb'
    marginTop='2em'
    center>
  <b>{props.title}</b><br/>
  {props.place}
</Inner>
