import React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'
import { Inner } from '../responsive/'

export default props => <FooterLink to={props.to}>
<Inner center size='0.8em' marginBottom='1em'>{props.children}</Inner>
</FooterLink>


const FooterLink = styled(Link)`
  color: #FFF;
  text-decoration: none;
`
