import React from 'react'
import styled from 'styled-components'
import { keyframes } from 'styled-components'
import size from '../responsive/size'
import HomeLink from './link'
import Link from 'gatsby-link'
import { Container, Inner,OnlySP } from '../responsive/'

class HeaderSPView extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      first: true,
      active: false
    }
  }

  click(){
    this.setState({
      first: false,
      active: !this.state.active
    })
  }

  render(props) {
    return (
      <OnlySP>
        <HeaderSP {...props}>
          <Container padding='0'>
              <Logo>
                <HomeLink to="/" color='#FFF'>
                  <Inner>DrupalCampDEN Japan Vol.1</Inner>
                </HomeLink>
              </Logo>
                {this.state.active &&
                  <Toggle onClick={() => this.click()}>
                    <ToggleFirstOpen />
                    <ToggleSecondOpen />
                    <ToggleThirdOpen />
                  </Toggle>
                }
                {!this.state.active && this.state.first &&
                  <Toggle onClick={() => this.click()}>
                    <ToggleFirst />
                    <ToggleSecond />
                    <ToggleThird />
                  </Toggle>
                }
                {!this.state.active && !this.state.first &&
                  <Toggle onClick={() => this.click()}>
                    <ToggleFirstClose />
                    <ToggleSecondClose />
                    <ToggleThirdClose />
                  </Toggle>
                }
          </Container>
        </HeaderSP>
        {this.state.active &&
          <Navigation>
            <NavigationUnder>
              <HeaderLink to='/'><Inner padding='1em' borderBottom borderColor=' hsla(0, 0%, 100%, 0.5)'>Home</Inner></HeaderLink>
              <HeaderLink to='/about'><Inner padding='1em' borderBottom borderColor=' hsla(0, 0%, 100%, 0.5)'>About DEN</Inner></HeaderLink>
              <HeaderLink to='/sessions'><Inner padding='1em' borderBottom borderColor=' hsla(0, 0%, 100%, 0.5)'>セッション</Inner></HeaderLink>
              <HeaderLink to='/venue'><Inner padding='1em' borderBottom borderColor=' hsla(0, 0%, 100%, 0.5)'>開催場所</Inner></HeaderLink>
              <HeaderLink to='/volunteer'><Inner padding='1em' borderBottom borderColor=' hsla(0, 0%, 100%, 0.5)'>ボランティア募集</Inner></HeaderLink>
              <HeaderLink to='/diversity-inclusion'><Inner padding='1em' borderBottom borderColor=' hsla(0, 0%, 100%, 0.5)'>多様性とインクルージョン</Inner></HeaderLink>
            </NavigationUnder>
          </Navigation>
        }
      </OnlySP>
    )
  }
}

export default HeaderSPView

const HeaderSP = styled.div`
  position: fixed;
  height: 3.5em;
  width: 100%;
  background:#1f7ea7;
  z-index: 999;
`

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const Navigation = styled.nav`
  margin-top: 3.5em;
  position: fixed;
  width: 100%;
  background:#1f7ea7;
  border-top: solid 1px #FFF;
  z-index: 999;
  opacity: 1;
  animation ${fade} 0.5s;
  @media only screen and (min-width: ${size.pc + 1}px) {
    display: none;
  }
`
const NavigationUnder = styled.ul`
  height:100%;
  overflow:scroll;
  @media only screen and (min-width: ${size.pc + 1}px) {
    display: none;
  }
`

const HeaderLink = styled(Link)`
  color: #FFF;
  text-decoration: none;
`

const Logo = styled.div`
  position: absolute;
  left: 1em;
`

const Toggle = styled.div`
  position: absolute;
  right: 2em;
  width: 18px;
  height: 15px;
`

const ToggleFirst = styled.span`
  top:1em;
  background: #FFF;
  height: 4px;
  left: 0;
  position: absolute;
  width: 2em;
`
const ToggleSecond = styled.span`
  top:1.7em;
  background: #FFF;
  height: 4px;
  left: 0;
  position: absolute;
  width: 2em;
`
const ToggleThird = styled.span`
  top:2.4em;
  background: #FFF;
  height: 4px;
  left: 0;
  position: absolute;
  width: 2em;
`

const firstOpen = keyframes`
  from {
    transform: rotate(0);
    top:1em;
  }
  to {
    transform: rotate(315deg);
    top:1.7em;
  }
`
const ToggleFirstOpen = styled.span`
  top:1.7em;
  background: #FFF;
  height: 4px;
  left: 0;
  position: absolute;
  width: 2em;
  transform: rotate(315deg);
  animation: ${firstOpen} 0.5s;
`

const secondOpen = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const ToggleSecondOpen = styled.span`
  top:1.7em;
  background: #FFF;
  height: 4px;
  left: 0;
  position: absolute;
  width: 2em;
  animation: ${secondOpen} 0.5s;
  opacity: 0;
`

const thirdOpen = keyframes`
  from {
    transform: rotate(0);
    top:2.4em;
  }
  to {
    transform: rotate(-315deg);
    top:1.7em;
  }
`

const ToggleThirdOpen = styled.span`
  top:1.7em;
  background: #FFF;
  height: 4px;
  left: 0;
  position: absolute;
  width: 2em;
  transform: rotate(-315deg);
  animation: ${thirdOpen} 0.5s;
`

const firstClose = keyframes`
  from {
    transform: rotate(315deg);
    top:1.7em;
  }
  to {
    transform: rotate(0);
    top:1em;
  }
`

const ToggleFirstClose = styled.span`
  top:1em;
  background: #FFF;
  height: 4px;
  left: 0;
  position: absolute;
  width: 2em;
  transform: rotate(0);
  animation: ${firstClose} 0.5s;
`

const secondClose = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const ToggleSecondClose = styled.span`
  top:1.7em;
  background: #FFF;
  height: 4px;
  left: 0;
  position: absolute;
  width: 2em;
  animation: ${secondClose} 0.5s;
  opacity: 1;
`

const thirdClose = keyframes`
  from {
    transform: rotate(-315deg);
    top:1.7em;
  }
  to {
    transform: rotate(0);
    top:2.4em;
  }
`

const ToggleThirdClose = styled.span`
  top:2.4em;
  background: #FFF;
  height: 4px;
  left: 0;
  position: absolute;
  width: 2em;
  transform: rotate(0);
  animation: ${thirdClose} 0.5s;
`
